import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {

  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { ListTLink, CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';
import MagneticButton from '../../components/MagneticButton';

import Icon from '../../components/Icon';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  const { t } = useTranslation();
  const { languages, originalPath, i18n } = useI18next();

  const langCode = i18n.language;
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
      <SEO
  title="Graafiline disain ja reklaami kujundamine"
  description="Vajad graafilist disaini? Ära piirdu keskpärasusega ja vaata, mida pakume. Töö kiire ja kvaliteetne."
  image="/pagecover-teenused.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Graafiline disain ja reklaami kujundamine",
        "provider": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Graafiline disain, trükimeedia ja reklaamide kujundus",
        "description": "Pakume kvaliteetset graafilist disaini, sealhulgas trüki- ja digireklaamide kujundust, brändiidentiteeti ning animatsioone.",
        "url": "https://give.ee/teenused/graafiline-disain/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digiagentuur",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Graafilise disaini portfoolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "about": "Disainime kvaliteetseid reklaame ja trükiseid, mis aitavad ettevõtetel oma brändi esile tõsta.",
        "url": "https://give.ee/teenused/graafiline-disain/",
        "image": "https://give.ee/pagecover-teenused.jpg"
      }
    ]
  }}
/>

      </GiveContentTrans>
      <GiveContentTrans language="en">
      <SEO
  title="Graphic design and advertising campaigns"
  description="Do you need help with graphic design? We create stunning print and digital materials that turn heads and drive results."
  image="/pagecover-en-default.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Graphic Design and Advertising",
        "provider": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Graphic design, branding, and digital advertising",
        "description": "We provide high-quality graphic design services, including print and digital advertising, branding, and animations.",
        "url": "https://give.ee/en/services/graphic-design/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digital Agency",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Graphic Design Portfolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "about": "We create high-quality graphic designs and advertising materials that help businesses enhance their brand presence.",
        "url": "https://give.ee/en/services/graphic-design/",
        "image": "https://give.ee/pagecover-en-default.jpg"
      }
    ]
  }}
/>

      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Graafiline disain ja reklaamide kujundamine</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Graphic design and advertising</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Vajad kiirt ja kvaliteetset abi graafilise disainiga? Aitame nii
              prinditud kui digitaalsete disainide kujundamisega. Muudame teie sõnumid selgeks ja silmapaistvaks.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              Need quick and quality help with graphic design? Our graphic designer can help you with both
              print and digital formats. 
              We transform your messages into clear and memorable designs.
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-100">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/graafilinedisain1.jpg"
            alt="Ajalehe reklaami graafiline disain"
            title="Graafiline disain ajalehereklaamile"
            layout="fullWidth"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/graafilinedisain1.jpg"
            alt="Newspaper cover ad graphic design"
            layout="fullWidth"
          />
        </GiveContentTrans>
      </div>

      

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Graafiline disain on investeering</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Graphic design is an investment</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
              Oled kunagi mõelnud, kuidas mõned reklaamid jäävad silma? Graafiline disain on teadus, mis ühendab värvid, kujundid ja kirjatüübid, et luua selget ja huvitavat sisu.
              Kujundame loovlahendusi välireklaamile, mida märgatakse. Reklaam mis jääb silma suurendab sinu brändi tuntust, usaldust ja lõppkokkuvõttes ka müük. 
              </p>
              <p>
        
                OOH (Out of Home) reklaamis on esile tõusnud digiekraanid. 
                Give graafilisel disaineril on suur kogemus animeeritud reklaamide kujundamisega.
                </p>
              <p>
                See eristab meid teistest graafilise disaini agentuuridest. Pakkume teile midagi rohkemat. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <p>
  Have you ever wondered why some ads catch your eye? Graphic design is a science that combines colors, shapes, and fonts to create clear and engaging content. 
  We design creative solutions for ads that get noticed. Ads that catch the eye increase your brand awareness, trust, and ultimately sales.
</p>
<p>
  Digital screens have become prominent in OOH (Out of Home) advertising. Give graphic designer has extensive experience in creating animated advertisements.
</p>
<p>
  This sets us apart from other graphic design agencies. Give offer you something extra.
</p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafilinedisain2.jpg"
                alt="Lauarääkija graafiline disain"
                title="Graafiline disain trükistele"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafilinedisain2.jpg"
                alt="Print advertisment"
                title="Graphic Design for print"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>
        </div>

        <div className="content-row has-media">

        <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafilinedisain3.jpg"
                alt="Visiitkaardi graafiline disain"
                title="Graafiline disain"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafilinedisain3.jpg"
                alt="Graphic design"
                title="Graphic design for business card"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>


          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>
                Graafilise disaini teenused
              </h3>
          <ul>
          <li>OOH reklaami disain</li>
  <li>Reklaamplakatite disain</li>
  <li>Voldikute, brošüüride jm. trükiste kujundamine</li>
  <li>Visiitkaartide kujundamine</li>
  <li>Brändimaterjalide kujundamine (<ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             logo kujundamine
            </ListTLink>, visuaalne identiteet jne)</li>
  <li>Pakendikujundus</li>
  <li>Infograafika disain</li>
  <li>Digitaalse reklaami disain</li>
  <li>Sotsiaalmeedia graafika kujundus</li>
  <li>Messibokside kujundus</li>
  <li>Presentatsioonide ja "pitch deckide" disain</li>
  <li>Töökuulutuse disain</li>
  <li><ListTLink
              to="/teenused/html5bannerid/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             Bänneri kujundus
            </ListTLink></li>
  <li>Illustratsioonid</li>
  <li>Autokleebiste graafiline disain</li>
  <li>E-kaartide, kutsete ja diplomite disain</li>

</ul>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <h3>
  Graphic Design Services
</h3>
<ul>
  <li>Graphic Design of advertising posters</li>
  <li>Design of leaflets, brochures, and other printed materials</li>
  <li>Design of business cards</li>
  <li>Brand material design (<ListTLink
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             logo design
            </ListTLink>, visual identity, etc.)</li>
  <li>Packaging design</li>
  <li>Creation of infographics</li>
  <li>Design of digital advertising</li>
  <li>Social media graphics</li>
  <li>Design of exhibition booths</li>
  <li>Designing presentations and pitch decks</li>
  <li><ListTLink
              to="/services/html5banners/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             Web banner design
            </ListTLink></li>
  <li>Creation of illustrations</li>
  <li>Graphic Design of car stickers</li>
  <li>Graphic Design of e-cards, invitations, and diplomas</li>
</ul>
            </GiveContentTrans>
          </div>
  
        </div>



        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Graafilise disaini tööd</h2>
          </div>

        <div className="content-row has-media">

        <div className="gd-grid">

            <div className="gd-grid-item">
  
            <StaticImage
                  src="../../assets/Graafiline-disain-digiekraanile.jpg"
                  alt="Graafiline disain digiekraanil"
                  layout="fullWidth"
                  title="Graafiline disain digiekraanil"
                />
            </div>

            <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/graafiline-disain-kott.jpg"
        alt="Kandekotti graafiline disain"
        layout="fullWidth"
        title="Kandekotti graafiline disain"
      />
  </div>

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile2.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-OOH.jpg"
        alt="Graafiline disain OOH reklaamile"
        layout="fullWidth"
        title="OOH reklaami disain"
      />
  </div>

 

  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-OOH2.jpg"
        alt="Graafiline disain OOH reklaamile"
        layout="fullWidth"
        title="OOH reklaami kujundus"
      />
  </div>


  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile3.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>


        
  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Menuu-graafiline-disain.jpg"
        alt="Restorani menüü graafiline disain"
        layout="fullWidth"
        title="Menüü graafiline disain"
      />
  </div>


  <div className="gd-grid-item">
  
  <StaticImage
        src="../../assets/Graafiline-disain-digiekraanile4.jpg"
        alt="Graafiline disain digiekraanil"
        layout="fullWidth"
        title="Graafiline disain digiekraanil"
      />
  </div>


  

          </div>
       </div>
       </section>
       </GiveContentTrans>



        <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>Graafilise disaini kohta</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Mis kogemus on Gives graafilise disainiga?</h3>
      <div className="answer">
      <p>Give on spetsialiseerunud kasutajasõbralike ja tipptasemel <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >UI/UX</ListTLink> disaini ja <ListTLink
            to="/teenused/kodulehed/"
            exit={{
              length: 1,
              zIndex: 2, // exit default is 0
              trigger: ({ exit, e, node }) => animateObjects(exit, node),
            }}
            entry={{
              delay: 0.5,
              length: 0.6,
              trigger: ({ entry, node }) => newContent(node),
            }}
          >kodulehtede</ListTLink> valmistamisele. Paljusid kliente aitame ka disaini terviklahendusega. Meie disainer on ligi 20 aasta jooksul kujundanud 
        erinevaid brände ja kõikvõimalikku graafikat.</p>

      <p>Kui me ise midagi teha ei oska või leiame, et on mõistlik kasutame sobivadi eriala spetsialiste (nt. illustraatorit, 3D disainerit, desktopperit). </p>
      <p>Statistiliselt on Give disaineril kogemust üle 1000 erineva graafilise disaini, brändi või UI/UX disaini projektiga.</p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kui palju maksab graafilise disaineri töö?</h3>
      <div className="answer">
      <p>Graafilise disaini projekti hind sõltub töömahust.</p>
      <p>Disainide tellimisel ei pea kartma üllatuskulusid. Kujundusgraafika projektide puhul teeme hinnapakkumise ja te ei pea muretsema üllatavate kulude eest. 
        </p>
        <p>Pakume ka fikseeritud kuumaksuga (3507€+km) piiramatut disaini tervikteenust. Lisaks on paketis ka Framer/Webflow kodulehe valmistamise, halduse ja ui/ux disaini teenused. 
          Nii saate tellida piiramatult kujundustöid ja ei pea muretsema iga üksiku projekti hinnastamise üle. Enamik ülesandeid va. suuremahulised tööd valmivad tööpäevadel 48 tunni jooksul. Selle teenusega võite säästa aastas kümmneid tuhandeid eurosid. 
        </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kui mulle ei meeldi teie töö kas teete ka muudatusi?</h3>
      <div className="answer">
      <p>Jah, ikka teeme!</p>
      <p>Meile on tähtis, et tunneksid uhkust selle disaini üle mida meilt saad. Omalt poolt alati selgitame miks mingisugused disainialased otsused langetasime ja kuidas meie graafilise disaineri mõtte töötas.
        </p>
      <p>
        Graafilises disainis ei saa alati eristada õiget ja valet. Ei ole olemas ühte lahendust mis päris kindlasti töötab. Loovtöö protsessi osa on proovida erinevaid lahendusi kuni selgub see õige. 
        Disaini projektides proovime tavaliselt läbi mitu erinevat lahendust enne kui jõuame ideeni mis on õige ja sobib teile esitlemiseks. 
         </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mida tähendab prepress ehk trükiettevalmistus</h3>
      <div className="answer">
      <p>Prepress on graafilise disaini protsessi osa, mis hõlmab ettevalmistustöid trükimeedia tootmiseks. See etapp on oluline, kuna tagab trükifailide kvaliteedi ja sobivuse trükimasinatele. Prepress sisaldab selliseid tegevusi nagu värvihaldus, kujunduse optimeerimine trükiks, failiformaatide kohandamine trükimasinatele ja värvi eraldamine erinevate trükikomponentide jaoks. Selle eesmärk on tagada trükimeedia, nagu ajakirjad, brošüürid, plakatid jms, kvaliteetne ja veatu tootmine.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Kuidas luua ühtne visuaalne brändi keel erinevates kanalites?</h3>
      <div className="answer">
      
      <p>Ühtse visuaalse keele kasutamine on iga brändi jaoks oluline. See aitab teie ettevõttel silma paista ning meelde jääda. Selle vundamendiks võiks olla tugev 
      brändiraamat. Mis see on saad lugeda <ListTLink
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             firmastiili ja logode kujundamise teenuse lehelt
            </ListTLink>.
      </p>

      <p>Kasutage sarnaseid värvitoone, fonte ja graafikat erinevates materjalides, et säilitada brändi ühtsus.</p>

      <p>Kõige parem on aga kasutada võimalikult paljude tööde jaoks ühte agentuuri. Ja soovitatavalt väikest. Nii on kõikidele osapooltele kõik asjad alati teada ja saab hoida kokku aega.</p>

      </div>
    </li>

 

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Miliseid graafilise disaini programme kasutate?</h3>
      <div className="answer">
      <p>Kasutame Adobe Creative Cloudis olevat kujundustarkvara. Digitaalse UI/UX disiani puhul lisaks Figmat või Sketci.</p>
      <p>Kliendina ei peaks te muretsema, et midagi jääb programmide taha. Omame kogemust Adobe Photoshopi, Illustraatori, AfterEffectsi, InDesigni ja teiste programmidega. 3D programmidest kasutame vabavaralist Blenderit ja Spline.</p>
      <p>Kui soovite meile edastada tööfaile oleks hea kui need kuuluvad Adobe tooteperekonda. Aga alati tasub üle küsida ja usun, et leiame lahendused ka muudele erijuhtudele.</p>
      </div>
    </li>



    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Kui kiiresti saate minu disaini projekti lõpetada?</h3>
      <div className="answer">
      <p>Kõik sõltub projektist aga üldiselt saame seda teha kiiremini kui teised reklaamiagentuurid. Meil on väljakujunenud töövõtted ja kiire suhtlus ning pikaaegne kogemust. Liites need valemi osad kokku saame tulemuseks kiire ja kvaliteetse töö. 
      
      </p>
      <p>
        Give on väike agentuur ja meie töömaht on väga erinev. Mõnikord on jube kiire erinevate väiksemate tööde kujundamisega ja teinekord on rohkem vaba aega või tegeleme pikemate projektidega ning saame 
        pisemaid graafilise disaini projekte nende vahele paigutada. Iga graafilise disaini projekti puhul peab arvestama, et töö valmimise aeg sõltub kliendilt saadud tagasisidest ja selle kiirusest. 
      </p>
      <p>Kui lepime kokku tähtaja siis võite olla kindlad, et töö selleks ajaks ka valmib. Tähtaegadest kinni pidamine on meie jaoks oluline.</p>
     
      </div>
    </li>
  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>




        <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Graafilise disaini tellimine on super lihtne</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Ordering graphic design work is super easy</h2>
        </GiveContentTrans>

        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Graafilise disaini tellimine ei pea olema keeruline ja ka mitte kallis. Kvaliteetne disain on investeering mis teenib ennast tagasi. Läbimõeldud loovlahendus aitab edastada sõnumeid selgelt 
                ja attraktiivne graafiline disain muudab need meeldejäävaks.
              </p>
              <h3>Soovid oma brändi viia uuele tasemele?</h3>
              <p>
              <ListTLink
              to="/kontakt/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
            Saada meile päring
            </ListTLink> mida sul oleks esimesena vaja või kui sa päris täpselt veel ei tea siis broneeri tasuta kõne ja arutame. 
              </p>
              <a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Broneeri tasuta videokõne"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
              Graphic design plays a crucial role in marketing. Thoughtful graphics help convey messages and emotions clearly and effectively. For instance, attractive and well-designed advertising posters, online ads, and social media posts draw more attention and increase the likelihood that people will engage with them and purchase products or services.
              </p><p>
Graphic design is a strategic investment that helps companies build a strong brand, boost sales, and gain a competitive edge. High-quality graphics facilitate more efficient message delivery and shape the company's identity and reputation, fostering long-term relationships with customers and promoting business success and growth.
              </p>
            </GiveContentTrans>
          </div>
        </div>


        <div className="media">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/graafiline-disain-plakat.jpg"
                alt="Graafiline disain plakat"
                layout="fullWidth"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/graafiline-disain-plakat.jpg"
                alt="Graphic Design Poster"
                layout="fullWidth"
              />
            </GiveContentTrans>
          </div>



      </section>

        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/logo-kujundamine/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Logo ja brändiraamatu kujundamine
            </CTACard>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/brand-identity/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Brandbook creation
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>
      </main>
      </Layout>
  
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;